<template>
  <v-container fluid>

    <v-sheet elevation="6">
      <v-tabs
        v-model="tabIndex"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(tab, index) in lesson.tabs"
          :key="tab.lessontabid"
          @click="loadTab(index)"
          style="text-transform: none !important;"
        >
          <v-menu
            v-if="moderator"
            offset-y              
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"              
                style="cursor: pointer;"
              >mdi-dots-vertical</v-icon>&nbsp;
            </template>
            <v-list>
              <v-list-item @click="renameLessonTab(tab)" style="cursor: pointer;"><v-list-item-title>Rename</v-list-item-title></v-list-item>
              <v-list-item @click="deleteLessonTab(tab, index)" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>          
          {{ tab.tabname }}
        </v-tab>         
      </v-tabs>
    </v-sheet> 
    <v-row
      v-if="moderator"
    >
      <v-btn
        small
        class="ma-4"
        @click="newLessonTab"
      >
        <v-icon>
          mdi-plus-circle-outline
        </v-icon>               
        &nbsp;&nbsp;New tab       
      </v-btn>
    </v-row>        
    <br />

    <span
      v-if="hasRadioGroups && showAnswers"
    >
      <strong>You got {{ totalCorrect }} questions correct out of {{ totalQuestions }}, which gives you a score of {{ percentage }}%</strong>.<br /><br />
    </span>

    <v-btn
      v-if="hasRadioGroups && showAnswers"
      @click="tryAgain"
    >
      Try Again
    </v-btn>

    <span
      v-if="hasRadioGroups && showAnswers"
    ><br /><br /></span>

    <v-divider
      v-if="hasRadioGroups && showAnswers"
    ></v-divider>
    <span
      v-if="hasRadioGroups && showAnswers"
    ><br /></span>

    <widget      
      v-for="(widget, index) in widgets"
      :key="index"
      :index="index"
      :widget="widget"
      :moderator="moderator"
      :showAnswers="showAnswers"
      @openExercise="openExercise"
      @moveWidgetUp="moveWidgetUp"
      @moveWidgetDown="moveWidgetDown"
      @moveWidgetToTab="moveWidgetToTab"
      @deleteWidget="deleteWidget"
      @userAction="submitUserAction"
    >
    </widget>

    <v-btn
      v-if="hasRadioGroups && !showAnswers"
      @click="markAnswers"
    >
      Mark answers
    </v-btn>

    <p      
      v-if="moderator && lessonLoaded"
    >
      <v-menu
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">    
          <v-btn
            v-bind="attrs"
            v-on="on"              
            small
          >
            <v-icon>
              mdi-plus-circle-outline
            </v-icon>               
            &nbsp;&nbsp;New widget       
          </v-btn>

          <!--<v-icon
            v-bind="attrs"
            v-on="on"              
            style="cursor: pointer;"
          >mdi-plus-circle-outline</v-icon>-->
        </template>
        <v-list>
          <v-list-item @click="appendWidget('bulletlist')" style="cursor: pointer;"><v-list-item-title>Bullet List</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('checkboxgroup')" style="cursor: pointer;"><v-list-item-title>Checkbox Group</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('codeblock')" style="cursor: pointer;"><v-list-item-title>Code Block</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('codesolution')" style="cursor: pointer;"><v-list-item-title>Code Solution</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('flipbook')" style="cursor: pointer;"><v-list-item-title>Flipbook</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('header')" style="cursor: pointer;"><v-list-item-title>Header</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('image')" style="cursor: pointer;"><v-list-item-title>Image</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('link')" style="cursor: pointer;"><v-list-item-title>Link</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('exercise')" style="cursor: pointer;"><v-list-item-title>Exercise</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('numberedlist')" style="cursor: pointer;"><v-list-item-title>Numbered List</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('pdf')" style="cursor: pointer;"><v-list-item-title>PDF</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('radiobuttongroup')" style="cursor: pointer;"><v-list-item-title>Radio Button Group</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('text')" style="cursor: pointer;"><v-list-item-title>Text</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('textbox')" style="cursor: pointer;"><v-list-item-title>Textbox</v-list-item-title></v-list-item>
          <v-list-item @click="appendWidget('youtubevideo')" style="cursor: pointer;"><v-list-item-title>Video</v-list-item-title></v-list-item>
        </v-list>
      </v-menu>          
    </p>

    <div
      style="height:700px;"
    >&nbsp;</div>

    <v-dialog
      v-if="moderator"
      v-model="dialogDestinationTab"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Destination Tab
        </v-card-title>

        <v-card-text>

          <v-container>

            <v-select
              v-model="toLessonTabId"
              :items="targetTabs"
              item-text="tabname"
              item-value="lessontabid"
              label="Select destination tab"
              solo
            ></v-select>  

          </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogDestinationTab = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="applyMoveWidgetToTab"
          >
            Move Widget
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>  
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const CourseRepository = RepositoryFactory.get('course');

  export default {
    name: 'LessonViewer',

    components: {
      widget: () => import("@/components/Widget"),
    },

    data: () => ({
      currentWidgetIndex: -1,
      lessonLoaded: false,
      widgets: [],
      tabIndex: 0,
      dialogDestinationTab: false,
      selectedLessonWidgetIndex: -1,
      toLessonTabId: 0,
      hasRadioGroups: false,
      showAnswers: false,
      totalQuestions: 0
    }),

    props: {    
      courseId: {
        type: Number,
        required: true,
        default: 0
      },       
      moderator: {
        type: Boolean,
        default: false
      },      
      lesson: {
        type: Object,
        required: true,
        default: null
      },
    },

    watch: {

      lesson: function (val) {
        if (val !== null) {
          this.tabIndex = this.$store.state.tabIndex;
          this.loadTab(this.tabIndex);
          this.lessonLoaded = true;
        } else {
          this.lessonLoaded = false;
        }        
      }

    },

    computed: {
      targetTabs() {
        var items = [];
        var i = 0;
        while (i < this.lesson.tabs.length) {
          if (i != this.tabIndex) {
            items.push(this.lesson.tabs[i]);
          }
          i++;
        }
        return items;
      },

      totalCorrect() {
        let result = 0;
        let i = 0;
        while (i < this.widgets.length) {
          if ((this.widgets[i].widgettypeid==7) && !this.moderator) {
            if (this.widgets[i].answer === 0) {
              result++;
            }
          }
          i++;
        }
        return result;
      },

      percentage() {
        let result = 0;
        if (this.totalQuestions > 0) {
          result = Math.floor((this.totalCorrect / this.totalQuestions) * 100);
        }
        return result;
      }

    },

    mounted()  {
      this.tabIndex = this.$store.state.tabIndex;
      //if (this.tabIndex === undefined) {
      //  this.$store.state.tabIndex = 0;
      //  this.tabIndex = 0;
      //}      
      this.loadTab(this.tabIndex);
    },

    methods: {

      async appendWidget(widgetType) {    

        const { data } = await CourseRepository.appendWidget(this.lesson.tabs[this.tabIndex].lessontabid, widgetType);
        if (data.success) {  

          this.widgets.push(data.data.widget);
          this.$forceUpdate();          

        } else {          
          errorUtils.showError(data.message);
        }

      },

      async deleteWidget(index) {    

        var r = confirm("Are you sure you want to delete the widget?");
        if (r == true) {
          const { data } = await CourseRepository.deleteWidget(this.widgets[index].lessonwidgetid);
          if (data.success) {  
            this.widgets.splice(index, 1);
            this.$forceUpdate();          
          } else {          
            errorUtils.showError(data.message);
          }
        } 
        
      },

      async loadTab(tabIndex) {
        if (this.lesson.tabs) {   
          const { data } = await CourseRepository.getLessonTabWidgets(this.lesson.tabs[tabIndex].lessontabid);
          if (data.success) {            
            this.widgets = data.data.widgets;    
            this.$store.state.tabIndex = tabIndex;

            this.hasRadioGroups = false;
            let i = 0;
            while (i < this.widgets.length) {
              if ((this.widgets[i].widgettypeid==7) && !this.moderator) {
                this.hasRadioGroups = true;
                this.totalQuestions++;

                let j = 0;
                while (j < this.widgets[i].instance.items.length) {
                  this.widgets[i].instance.items[j]['index'] = j;
                  j++;
                }

                this.widgets[i].instance.items.sort( () => Math.random() - 0.5);

              }
              i++;
            }
            
          } else {          
            errorUtils.showError(data.message);
          }   
        }     
      },

      async moveWidgetUp(index) {        
        if (index > 0) {

          const { data } = await CourseRepository.moveWidgetUp(this.widgets[index].lessonwidgetid);
          if (data.success) {  

            var tmpWidget = this.widgets[index - 1];
            this.widgets[index - 1] = this.widgets[index];
            this.widgets[index] = tmpWidget;
            this.$forceUpdate();

          } else {          
            errorUtils.showError(data.message);
          }

        }
      },

      async moveWidgetDown(index) {        
        if (index < (this.widgets.length - 1)) {

          const { data } = await CourseRepository.moveWidgetDown(this.widgets[index].lessonwidgetid);
          if (data.success) {  

            var tmpWidget = this.widgets[index];
            this.widgets[index] = this.widgets[index + 1];
            this.widgets[index + 1] = tmpWidget;
            this.$forceUpdate();

          } else {          
            errorUtils.showError(data.message);
          }

        }
      },

      moveWidgetToTab(index) {       
        this.selectedLessonWidgetIndex = index;         
        this.dialogDestinationTab = true;
      },

      async applyMoveWidgetToTab() {

        const { data } = await CourseRepository.moveWidgetToTab(this.widgets[this.selectedLessonWidgetIndex].lessonwidgetid, this.toLessonTabId);
        if (data.success) {  
          this.widgets.splice(this.selectedLessonWidgetIndex, 1);
          this.$forceUpdate();     
        } else {          
          errorUtils.showError(data.message);
        }

        this.dialogDestinationTab = false;

      },

      async newLessonTab() {

        const { data } = await CourseRepository.newLessonTab(
          this.lesson.lessonid
        );
        if (data.success) {  
          this.lesson.tabs.push(data.data.tab);     
        } else {
          errorUtils.showError(data.message);
        }

      },

      async openExercise(widget) {         
        const lessonWidgetId = widget.lessonwidgetid;
        const exerciseId = widget.instance.exerciseid;
        let questions = 0;
        if (widget.instance.questions) {
          questions = widget.instance.questions;
        }
        this.$router.push( { path: '/exercise/' + lessonWidgetId + '/' + exerciseId + '/' + questions } );
      },

      rateLesson() {
        CourseRepository.rateLesson(this.lesson.lessonid, this.lesson.rating);
      },

      async renameLessonTab(tab) {
        let name = prompt("New tab name", tab.tabname);
        if (!(name == null || name == "")) {

          const { data } = await CourseRepository.renameLessonTab(tab.lessontabid, name);
          if (data.success) {  
            tab.tabname = name;
          } else {          
            errorUtils.showError(data.message);
          }
        }
      },

      async deleteLessonTab(tab, index) {

        const { data } = await CourseRepository.deleteLessonTab(tab.lessontabid);
        if (data.success) {  
          this.lesson.tabs.splice(index, 1);

          if (index > 0) {
            this.tabIndex = index - 1;
          } else {
            this.tabIndex = 0;
          }

          this.loadTab(this.tabIndex);

        } else {          
          errorUtils.showError(data.message);
        }

      },

      async markAnswers() {
        const { data } = await CourseRepository.submitScore(this.lesson.tabs[this.tabIndex].lessontabid, this.totalQuestions, this.totalCorrect);
        if (!data.success) {  
          errorUtils.showError(data.message);
        }

        this.showAnswers = true;
        this.$vuetify.goTo(0);
      },

      async tryAgain() {
        const { data } = await CourseRepository.clearActions(this.lesson.tabs[this.tabIndex].lessontabid);
        if (data.success) {  
          this.showAnswers = false;
          let i = 0;
          while (i < this.widgets.length) {
            if (this.widgets[i].widgettypeid==7) {
              this.widgets[i].answer = null;
            }
            i++;
          }       
        } else {
          errorUtils.showError(data.message);
        }
        this.$vuetify.goTo(0);
      },

      async submitUserAction(widget, index) {
        const { data } = await CourseRepository.submitAction(widget.lessonwidgetid, '' + index);
        if (!data.success) {  
          errorUtils.showError(data.message);
        }
      },

      toggleLessonCompleted() {
        if (this.lesson.completed) {
          CourseRepository.markLessonComplete(this.lesson.lessonid);
        } else {
          CourseRepository.clearLessonComplete(this.lesson.lessonid);
        }
      }

    }

  }
</script>

<style scoped>

</style>